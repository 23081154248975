import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box
} from "@chakra-ui/react";

const DayTransactionModal = ({ isOpen, onClose, day }) => {
  if (!day) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="50%" maxH="50%"> 
        <ModalHeader>Transactions for {day.date}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY={'scroll'}>
          <Text mb={4}>Total Amount: ₹{day.amount.toFixed(2)}</Text>
          {day.transactions && day.transactions.length > 0 ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>VPA</Th>
                  <Th>Amount</Th>
                  {/* <Th>Type</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {day.transactions.map((transaction, index) => (
                  <Tr key={index}>
                    <Td>{transaction.vpa}</Td>
                    <Td>₹{transaction.amount.toFixed(2)}</Td>
                    {/* <Td>{transaction.type}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Box>No transactions available for this day.</Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DayTransactionModal;