import React, { useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, LineChart, Line, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';
import { Box, Text, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import DayTransactionModal from './DayTransactionModal';

const HomeTransactionChart = ({ data, xKey, yKey, chartType, averageCurrentMonth, averagePreviousMonth, isDaily, averageMonthlySpend }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDay, setSelectedDay] = useState(null);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box bg="white" p={2} border="1px solid #ccc" borderRadius="md">
          <Text><strong>{label}</strong></Text>
          <Text><strong>Amount:</strong> ₹{payload[0].value.toFixed(2)}</Text>
        </Box>
      );
    }
    return null;
  };

  const handleBarClick = (data) => {
    if (isDaily && chartType === 'bar' && data && data.activePayload) {
      const clickedData = data.activePayload[0].payload;
      setSelectedDay(clickedData);
      onOpen();
    }
  };

  const renderChart = () => {
    const ChartComponent = chartType === 'bar' ? BarChart : LineChart;
    const DataComponent = chartType === 'bar' ? Bar : Line;

    return (
      <ChartComponent data={data} onClick={handleBarClick}>
        <XAxis dataKey={xKey} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <DataComponent type="monotone" dataKey={yKey} fill="#8884d8" stroke="#8884d8" />
        {isDaily && (
          <>
            <ReferenceLine y={averageCurrentMonth} stroke="green" strokeDasharray="3 3" />
            <ReferenceLine y={averagePreviousMonth} stroke="red" strokeDasharray="3 3" />
          </>
        )}
        {!isDaily && (
          <ReferenceLine y={averageMonthlySpend} label="Avg Monthly Spend" stroke="blue" strokeDasharray="3 3" />
        )}
      </ChartComponent>
    );
  };

  const CustomLegend = () => (
    <HStack spacing={4} justifyContent="center" mt={2}>
      {isDaily ? (
        <>
          <HStack>
            <Box w="10px" h="10px" bg="red" borderRadius="50%" />
            <Text fontSize="sm">Prev Month Avg: ₹{averagePreviousMonth.toFixed(2)}</Text>
          </HStack>
          <HStack>
            <Box w="10px" h="10px" bg="green" borderRadius="50%" />
            <Text fontSize="sm">Current Month Avg: ₹{averageCurrentMonth.toFixed(2)}</Text>
          </HStack>
        </>
      ) : (
        <HStack>
          <Box w="10px" h="10px" bg="blue" borderRadius="50%" />
          <Text fontSize="sm">Avg Monthly Spend: ₹{averageMonthlySpend.toFixed(2)}</Text>
        </HStack>
      )}
    </HStack>
  );

  return (
    <VStack>
      <ResponsiveContainer width="100%" height={300}>
        {renderChart()}
      </ResponsiveContainer>
      <CustomLegend />
      {selectedDay && (
        <DayTransactionModal isOpen={isOpen} onClose={onClose} day={selectedDay} />
      )}
    </VStack>
  );
};

export default HomeTransactionChart;