import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { openDB } from 'idb'; // Import the openDB function from idb

const LogoutButton = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearIndexedDB = async () => {
    try {
      const dbName = 'TransactionsDB'; // Replace with your actual database name
      const db = await openDB(dbName, 1);
      const storeNames = db.objectStoreNames;
      
      // Clear all object stores
      const tx = db.transaction(storeNames, 'readwrite');
      storeNames.forEach(storeName => {
        tx.objectStore(storeName).clear();
      });
      await tx.done;
      
      console.log('IndexedDB cleared successfully');
    } catch (error) {
      console.error('Error clearing IndexedDB:', error);
    }
  };

  const handleLogout = async () => {
    try {
      // const refresh_token = localStorage.getItem("refresh_token");
      // const access_token = localStorage.getItem("access_token");

      // Send request to blacklist the refresh token
      // await axios.post(
      //   `${process.env.REACT_APP_DEV_BACK_URL}/api/user/logout/`,
      //   { refresh_token },
      //   { headers: { Authorization: `Bearer ${access_token}` } }
      // );

      // Clear tokens from local storage
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");

      // Clear IndexedDB
      await clearIndexedDB();

      // Close the modal and redirect to landing page
      onClose();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);

      // Even if the server request fails, we should still clear local storage, IndexedDB, and redirect
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      await clearIndexedDB();
      onClose();
      navigate("/");
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="w-full text-left py-2 px-4 bg-transparent text-white hover:bg-neutral-600 rounded transition-colors"
      >
        Logout
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-black bg-opacity-60 backdrop-blur-sm" />
        <ModalContent className="bg-neutral-800 text-white rounded-md">
          <ModalHeader>Are you sure you want to log out?</ModalHeader>
          <ModalCloseButton className="text-white" />
          <ModalBody className="py-4">
            All your current Data will be removed from your Browser.
          </ModalBody>

          <ModalFooter>
            <button
              className="mr-3 px-4 py-2 bg-neutral-700 text-white rounded hover:bg-neutral-600 transition-colors"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              onClick={handleLogout}
            >
              Logout
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LogoutButton;