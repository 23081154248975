import { openDB } from 'idb';

const DB_NAME = 'TransactionsDB';
const STORE_NAME = 'transactions'; // Ensure the store name is consistent
const DB_VERSION = 2;

let dbPromise = null;

// Get the IndexedDB instance or initialize it if not present
async function getDB() {
  if (!dbPromise) {
    dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);

        // Ensure object store is created during upgrade
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          console.log(`Creating object store: ${STORE_NAME}`);
          db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
        } else {
          console.log(`Object store ${STORE_NAME} already exists`);
        }
      },
    });
  }
  return dbPromise;
}

// Store data in IndexedDB
export async function processAndStoreData(rawData) {
  console.log('Processing and storing data...');
  const db = await getDB();
  console.log(`Using database: ${DB_NAME} with store: ${STORE_NAME}`);

  const processedData = Object.entries(rawData.data).flatMap(([date, transactions]) =>
    transactions.map(transaction => ({
      ...transaction,
      date,
      id: `${date}-${transaction.vpa}-${transaction.amount}`, // Custom ID generation
    }))
  );

  try {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    
    await Promise.all(processedData.map(transaction => store.put(transaction)));
    await tx.done;
    console.log(`Stored ${processedData.length} transactions`);
  } catch (error) {
    console.error('Error in storing data:', error);
  }

  return processedData;
}

// Get all stored transactions from IndexedDB
export async function getTransactions() {
  console.log('Fetching transactions...');
  const db = await getDB();
  try {
    const transactions = await db.getAll(STORE_NAME);
    console.log(`Fetched ${transactions.length} transactions`);
    return transactions;
  } catch (error) {
    console.error(`Error fetching transactions from store ${STORE_NAME}:`, error);
    throw error; // Rethrow to be caught in calling function
  }
} 
  export function getCategoryAnalysis(transactions) {
    // Example implementation of category analysis
    const categoryTotals = transactions.reduce((totals, transaction) => {
      const { category, amount } = transaction;
      if (!totals[category]) totals[category] = 0;
      totals[category] += amount;
      return totals;
    }, {});
    return categoryTotals;
  }
  
  export function getTopSpends(transactions, limit = 30) {
    // Example implementation to get top spending transactions
    return transactions.sort((a, b) => b.amount - a.amount).slice(0, limit);
  }
  
  export const getRecurringTransactions = (transactions, startDate, endDate) => {
    return transactions
      .filter(transaction => {
        const transactionDate = new Date(transaction.date);
        return transactionDate >= startDate && transactionDate <= endDate;
      })
      .reduce((acc, transaction) => {
        const existing = acc.find(t => t.vpa === transaction.vpa);
        if (existing) {
          existing.amount += transaction.amount;
        } else {
          acc.push({ vpa: transaction.vpa, amount: transaction.amount });
        }
        return acc;
      }, []);
  };
  
export async function getVPAGroupTransactions(startDate, endDate) {
  const db = await getDB();
  const transactions = await db.getAll('transactions');

  console.log('Total transactions:', transactions.length);

  const filteredTransactions = transactions.filter(transaction => {
    const transactionDate = new Date(transaction.date);
    return transactionDate >= startDate && transactionDate <= endDate && transaction.type === 'debit';
  });

  console.log('Filtered transactions:', filteredTransactions.length);

  const vpaGroups = filteredTransactions.reduce((acc, transaction) => {
    const vpa = transaction.vpa || 'Unknown VPA';
    if (!acc[vpa]) {
      acc[vpa] = {
        vpa,
        amount: 0,
        count: 0
      };
    }
    acc[vpa].amount += Number(transaction.amount) || 0;
    acc[vpa].count += 1;
    return acc;
  }, {});

  const result = Object.values(vpaGroups).map(group => ({
    ...group,
    avgAmount: group.amount / group.count
  }));

  console.log('VPA groups:', result);
  return result;
}
  
  export function getMonthlySpending(transactions, year, type) {
    return transactions.reduce((monthlyTotals, transaction) => {
      const transactionDate = new Date(transaction.date);
      if (transactionDate.getFullYear() === year && transaction.type === type) {
        const month = transactionDate.getMonth();
        monthlyTotals[month] = (monthlyTotals[month] || 0) + transaction.amount;
      }
      return monthlyTotals;
    }, {});
  }
  
  export function getDailySpending(transactions, year, month, type) {
    return transactions.reduce((dailyTotals, transaction) => {
      const transactionDate = new Date(transaction.date);
      if (
        transactionDate.getFullYear() === year &&
        transactionDate.getMonth() === month - 1 &&
        transaction.type === type
      ) {
        const day = transactionDate.getDate();
        dailyTotals[day] = (dailyTotals[day] || 0) + transaction.amount;
      }
      return dailyTotals;
    }, {});
  }

  export function calculateBasicStats(transactions) {
    if (!transactions || transactions.length === 0) {
      return {
        totalSpent: 0,
        totalReceived: 0,
        netBalance: 0,
        transactionCount: 0,
        avgTransactionAmount: 0
      };
    }
  
    const stats = transactions.reduce((acc, transaction) => {
      const amount = transaction.amount || 0;
      if (transaction.type === 'debit') {
        acc.totalSpent += amount;
      } else if (transaction.type === 'credit') {
        acc.totalReceived += amount;
      }
      return acc;
    }, { totalSpent: 0, totalReceived: 0 });
  
    stats.netBalance = stats.totalReceived - stats.totalSpent;
    stats.transactionCount = transactions.length;
    stats.avgTransactionAmount = (stats.totalSpent + stats.totalReceived) / stats.transactionCount;
  
    return stats;
  }


  export async function getTransactionStatistics(startDate, endDate, previousStartDate, previousEndDate) {
    const db = await getDB();
    const transactions = await db.getAll('transactions');
  
    const filterTransactions = (start, end) => transactions.filter(t => {
      const date = new Date(t.date);
      return date >= start && date <= end;
    });
  
    const currentTransactions = filterTransactions(startDate, endDate);
    const previousTransactions = filterTransactions(previousStartDate, previousEndDate);
  
    const calculateStats = (trans) => {
      let totalDebit = 0, totalCredit = 0, highestTransaction = { amount: 0 }, lowestTransaction = { amount: Infinity };
      const vpaFrequency = {}, dailyTotals = {};
  
      trans.forEach(t => {
        const amount = Number(t.amount) || 0;
        const day = t.date.split('T')[0];
  
        if (t.type === 'debit') totalDebit += amount;
        else if (t.type === 'credit') totalCredit += amount;
  
        if (amount > highestTransaction.amount) highestTransaction = { ...t, amount };
        if (amount < lowestTransaction.amount) lowestTransaction = { ...t, amount };
  
        vpaFrequency[t.vpa] = (vpaFrequency[t.vpa] || 0) + 1;
        dailyTotals[day] = (dailyTotals[day] || 0) + amount;
      });
  
      const mostFrequentVPA = Object.entries(vpaFrequency).reduce((a, b) => b[1] > a[1] ? b : a, ['', 0]);
      const mostActiveDay = Object.entries(dailyTotals).reduce((a, b) => b[1] > a[1] ? b : a, ['', 0]);
  
      return {
        totalTransactions: trans.length,
        totalDebit,
        totalCredit,
        netBalance: totalCredit - totalDebit,
        avgTransactionAmount: (totalDebit + totalCredit) / trans.length,
        highestTransaction,
        lowestTransaction,
        mostFrequentVPA: { vpa: mostFrequentVPA[0], count: mostFrequentVPA[1] },
        mostActiveDay: { day: mostActiveDay[0], amount: mostActiveDay[1] },
        dailyTotals
      };
    };
  
    const currentStats = calculateStats(currentTransactions);
    const previousStats = calculateStats(previousTransactions);
  
    return {
      current: currentStats,
      previous: previousStats,
      changes: {
        totalTransactions: currentStats.totalTransactions - previousStats.totalTransactions,
        totalDebit: currentStats.totalDebit - previousStats.totalDebit,
        totalCredit: currentStats.totalCredit - previousStats.totalCredit,
        netBalance: currentStats.netBalance - previousStats.netBalance,
      }
    };
  }