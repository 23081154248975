import React, { useState, useEffect } from 'react';
import {
  Box, VStack, HStack, Text, Heading, Spinner, SimpleGrid,
  Stat, StatLabel, StatNumber, StatHelpText, StatArrow,
  Card, CardHeader, CardBody, Badge,
  Menu, MenuButton, MenuList, MenuItem, Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Table, Thead, Tbody, Tr, Th, Td,
  useDisclosure, Tooltip, FormControl, FormLabel, Input
} from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getTransactionStatistics } from '../utils/dataProcessing';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const StatisticsTab = () => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState('1m');
  const [modalContent, setModalContent] = useState(null);
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
  const { isOpen: isCustomDateOpen, onOpen: onCustomDateOpen, onClose: onCustomDateClose } = useDisclosure();

  const fetchStats = async () => {
    setIsLoading(true);
    const now = new Date();
    let startDate, endDate, previousStartDate, previousEndDate;

    switch (dateRange) {
      case '1m':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        previousStartDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDate = now;
        previousEndDate = new Date(startDate.getTime() - 1);
        break;
      case '3m':
        startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1);
        previousStartDate = new Date(now.getFullYear(), now.getMonth() - 5, 1);
        endDate = now;
        previousEndDate = new Date(startDate.getTime() - 1);
        break;
      case '6m':
        startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1);
        previousStartDate = new Date(now.getFullYear(), now.getMonth() - 11, 1);
        endDate = now;
        previousEndDate = new Date(startDate.getTime() - 1);
        break;
      case '1y':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), 1);
        previousStartDate = new Date(now.getFullYear() - 2, now.getMonth(), 1);
        endDate = now;
        previousEndDate = new Date(startDate.getTime() - 1);
        break;
      case 'custom':
        if (customStartDate && customEndDate) {
          startDate = new Date(customStartDate);
          endDate = new Date(customEndDate);
          const duration = endDate.getTime() - startDate.getTime();
          previousStartDate = new Date(startDate.getTime() - duration);
          previousEndDate = new Date(startDate.getTime() - 1);
        } else {
          setIsLoading(false);
          return;
        }
        break;
    }

    const statistics = await getTransactionStatistics(startDate, endDate, previousStartDate, previousEndDate);
    setStats(statistics);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStats();
  }, [dateRange, customStartDate, customEndDate]);

  const openDetailModal = (content) => {
    setModalContent(content);
    onDetailOpen();
  };

  const handleCustomDateSubmit = () => {
    if (customStartDate && customEndDate) {
      setDateRange('custom');
      onCustomDateClose();
    }
  };

  const StatCard = ({ title, value, change, onClick }) => (
    <Tooltip label="Click for details">
      <Card onClick={onClick} cursor="pointer" _hover={{ boxShadow: 'lg' }}>
        <CardHeader>
          <Heading size="md">{title}</Heading>
        </CardHeader>
        <CardBody>
          <Stat>
            <StatNumber>{value}</StatNumber>
            <StatHelpText>
              <StatArrow type={change > 0 ? 'increase' : 'decrease'} />
              {Math.abs(change).toFixed(2)}%
            </StatHelpText>
          </Stat>
        </CardBody>
      </Card>
    </Tooltip>
  );
// Add this helper function at the top of your component or in a separate utils file:
const getDaysInPeriod = (dateRange) => {
    const now = new Date();
    switch (dateRange) {
      case '1m':
        return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
      case '3m':
        return 91;
      case '6m':
        return 182;
      case '1y':
        return 365;
      case 'custom':
        if (customStartDate && customEndDate) {
          return (new Date(customEndDate) - new Date(customStartDate)) / (1000 * 60 * 60 * 24) + 1;
        }
        return 30; // default to 30 days if custom dates are not set
      default:
        return 30;
    }
  };

  if (isLoading) return <Spinner size="xl" />;

  const { current, previous, changes } = stats || {};

  return (
    <Box p={5}>
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between">
          <Heading size="lg">Transaction Statistics</Heading>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {dateRange === '1m' ? 'This Month' : 
               dateRange === '3m' ? 'Last 3 Months' : 
               dateRange === '6m' ? 'Last 6 Months' : 
               dateRange === '1y' ? 'Last Year' : 
               'Custom Range'}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setDateRange('1m')}>This Month</MenuItem>
              <MenuItem onClick={() => setDateRange('3m')}>Last 3 Months</MenuItem>
              <MenuItem onClick={() => setDateRange('6m')}>Last 6 Months</MenuItem>
              <MenuItem onClick={() => setDateRange('1y')}>Last Year</MenuItem>
              <MenuItem onClick={onCustomDateOpen}>Custom Range</MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        {stats && (
          <>
            <SimpleGrid columns={[1, 2, 3]} spacing={6}>
              <StatCard
                title="Total Transactions"
                value={current.totalTransactions}
                change={(current.totalTransactions - previous.totalTransactions) / previous.totalTransactions * 100}
                onClick={() => openDetailModal(
                    <VStack align="stretch">
                    <Heading size="md">Transaction Breakdown</Heading>
                    <Text>Current period: {current.totalTransactions}</Text>
                    <Text>Previous period: {previous.totalTransactions}</Text>
                    <Text>Avg. transactions per day: {(stats.current.totalTransactions / getDaysInPeriod(dateRange)).toFixed(2)}</Text>
                    <Text>Change: {changes.totalTransactions}</Text>
                  </VStack>
                )}
                />
              <StatCard
                title="Net Balance"
                value={`₹${current.netBalance.toFixed(2)}`}
                change={(current.netBalance - previous.netBalance) / Math.abs(previous.netBalance) * 100}
                onClick={() => openDetailModal(
                  <VStack align="stretch">
                    <Heading size="md">Net Balance Details</Heading>
                    <Text>Current period: ₹{current.netBalance.toFixed(2)}</Text>
                    <Text>Previous period: ₹{previous.netBalance.toFixed(2)}</Text>
                    <Text>Change: ₹{changes.netBalance.toFixed(2)}</Text>
                  </VStack>
                )}
              />
              <StatCard
                title="Average Transaction"
                value={`₹${current.avgTransactionAmount.toFixed(2)}`}
                change={(current.avgTransactionAmount - previous.avgTransactionAmount) / previous.avgTransactionAmount * 100}
                onClick={() => openDetailModal(
                  <VStack align="stretch">
                    <Heading size="md">Average Transaction Details</Heading>
                    <Text>Current period: ₹{current.avgTransactionAmount.toFixed(2)}</Text>
                    <Text>Previous period: ₹{previous.avgTransactionAmount.toFixed(2)}</Text>
                    <Text>Change: ₹{(current.avgTransactionAmount - previous.avgTransactionAmount).toFixed(2)}</Text>
                  </VStack>
                )}
              />
            </SimpleGrid>
            <SimpleGrid columns={[1, 2]} spacing={6}>
              <Card>
                <CardHeader>
                  <Heading size="md">Credit vs Debit</Heading>
                </CardHeader>
                <CardBody>
                  <Stat>
                    <StatLabel>Total Credit</StatLabel>
                    <StatNumber>₹{current.totalCredit.toFixed(2)}</StatNumber>
                    <StatHelpText>
                      <StatArrow type={changes.totalCredit > 0 ? 'increase' : 'decrease'} />
                      {Math.abs((changes.totalCredit / previous.totalCredit) * 100).toFixed(2)}%
                    </StatHelpText>
                  </Stat>
                  <Stat mt={4}>
                    <StatLabel>Total Debit</StatLabel>
                    <StatNumber>₹{current.totalDebit.toFixed(2)}</StatNumber>
                    <StatHelpText>
                      <StatArrow type={changes.totalDebit > 0 ? 'increase' : 'decrease'} />
                      {Math.abs((changes.totalDebit / previous.totalDebit) * 100).toFixed(2)}%
                    </StatHelpText>
                  </Stat>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Heading size="md">Transaction Highlights</Heading>
                </CardHeader>
                <CardBody>
                  <Text className='pt-2'><strong>Highest Transaction:</strong> ₹{current.highestTransaction.amount.toFixed(2)} ({current.highestTransaction.type})</Text>
                  <Text className='pt-2'><strong>Most Frequent VPA:</strong> {current.mostFrequentVPA.vpa} ({current.mostFrequentVPA.count} transactions)</Text>
                  <Text className='pt-2'><strong>Most Active Day:</strong> {current.mostActiveDay.day} (₹{current.mostActiveDay.amount.toFixed(2)})</Text>
                </CardBody>
              </Card>
            </SimpleGrid>
            <Card>
              <CardHeader>
                <Heading size="md">Daily Transaction Volume</Heading>
              </CardHeader>
              <CardBody>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={Object.entries(current.dailyTotals).map(([date, amount]) => ({ date, amount }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <RechartsTooltip />
                    <Line type="monotone" dataKey="amount" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </CardBody>
            </Card>


          </>
        )}
      </VStack>

      <Modal isOpen={isDetailOpen} onClose={onDetailClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detailed View</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalContent}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onDetailClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCustomDateOpen} onClose={onCustomDateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Custom Date Range</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                value={customStartDate}
                onChange={(e) => setCustomStartDate(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                value={customEndDate}
                onChange={(e) => setCustomEndDate(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCustomDateSubmit}>
              Apply
            </Button>
            <Button variant="ghost" onClick={onCustomDateClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StatisticsTab;