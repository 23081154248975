import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Spacer
} from "@chakra-ui/react";
import {
  getTransactions,
  getTopSpends,
} from "../utils/dataProcessing";
import TopSpendsTab from "../Components/TopSpendTab";
import VpaSpendingTab from "../Components/VpaSpendingTab";
import StatisticsTab from "../Components/StatisticsTabComponent";
import DataUpdateLoader from "../Components/DataUpdateLoader";

const Analytics = () => {
  const [topSpends, setTopSpends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactions = await getTransactions();
        if (transactions && transactions.length > 0) {
          setTopSpends(getTopSpends(transactions));
        } else {
          setError("No transactions found");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleUpdateComplete = () => {
    // Refresh your analytics data or component state here
    console.log('Data update completed');
    // You might want to re-fetch data here
    setIsLoading(true);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const transactions = await getTransactions();
      if (transactions && transactions.length > 0) {
        setTopSpends(getTopSpends(transactions));
        setError(null);
      } else {
        setError("No transactions found");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box p={5}>
      <VStack spacing={8} align="stretch">
        <Flex align="center">
          <Heading size="lg">Analytics</Heading>
          <Spacer />
          <DataUpdateLoader onComplete={handleUpdateComplete} />
        </Flex>
        <Tabs variant="enclosed" colorScheme="teal">
          <TabList>
            <Tab>Category Analysis</Tab>
            <Tab>Statistics</Tab>
            <Tab>Top Spends</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <VpaSpendingTab />
            </TabPanel>
            <TabPanel>
              <StatisticsTab />
            </TabPanel>
            <TabPanel>
              <TopSpendsTab topSpends={topSpends} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  );
};

export default Analytics;