import React, { useState, useEffect } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Modal, ModalOverlay, Center, ModalContent, ModalHeader,Spinner, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Select, VStack, useDisclosure, useToast, HStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import api from "../utils/api";

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [newSubscription, setNewSubscription] = useState({
    name: '',
    amount: '',
    startDate: '',
    period: 'monthly',
  });
  const [editingSubscription, setEditingSubscription] = useState(null);
  const [deletingSubscription, setDeletingSubscription] = useState(null);
  const toast = useToast();
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/subscriptions');
      setSubscriptions(response.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      toast({
        title: 'Error fetching subscriptions',
        description: error.response?.data?.error || 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }finally{
      setIsLoading(false);

    }
  };

  const handleInputChange = (e, setFunction) => {
    const { name, value } = e.target;
    setFunction(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (subscription, isEditing = false) => {
    try {
      const startDate = new Date(subscription.startDate);
      const formattedStartDate = startDate.toISOString();

      const subscriptionData = {
        ...subscription,
        startDate: formattedStartDate,
      };

      if (isEditing) {
        await api.patch(`/subscriptions/${subscription.ID}`, subscriptionData);
      } else {
        await api.post('/subscriptions', subscriptionData);
      }

      onAddClose();
      onEditClose();
      setNewSubscription({ name: '', amount: '', startDate: '', period: 'monthly' });
      setEditingSubscription(null);
      fetchSubscriptions();
      toast({
        title: `Subscription ${isEditing ? 'updated' : 'added'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'adding'} subscription:`, error);
      toast({
        title: `Error ${isEditing ? 'updating' : 'adding'} subscription`,
        description: error.response?.data?.error || 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteConfirm = async () => {
    if (!deletingSubscription) return;

    try {
      await api.delete(`/subscriptions/${deletingSubscription.ID}`);
      fetchSubscriptions();
      toast({
        title: 'Subscription deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting subscription:', error);
      toast({
        title: 'Error deleting subscription',
        description: error.response?.data?.error || 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDeletingSubscription(null);
      onDeleteClose();
    }
  };

  const handleEdit = (subscription) => {
    setEditingSubscription({
      ...subscription,
      startDate: format(parseISO(subscription.StartDate), 'yyyy-MM-dd'),
    });
    onEditOpen();
  };

  const handleDelete = (subscription) => {
    setDeletingSubscription(subscription);
    onDeleteOpen();
  };

  const SubscriptionForm = ({ subscription, setSubscription, onSubmit, isEditing }) => (
    <VStack spacing={4}>
      <FormControl>
        <FormLabel>Subscription Name</FormLabel>
        <Input name="name" value={subscription.name} onChange={(e) => handleInputChange(e, setSubscription)} />
      </FormControl>
      <FormControl>
        <FormLabel>Subscription Amount</FormLabel>
        <Input name="amount" value={subscription.amount} onChange={(e) => handleInputChange(e, setSubscription)} />
      </FormControl>
      <FormControl>
        <FormLabel>Start Date</FormLabel>
        <Input name="startDate" type="date" value={subscription.startDate} onChange={(e) => handleInputChange(e, setSubscription)} />
      </FormControl>
      <FormControl>
        <FormLabel>Subscription Period</FormLabel>
        <Select name="period" value={subscription.period} onChange={(e) => handleInputChange(e, setSubscription)}>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </FormControl>
      <Button colorScheme="blue" onClick={() => onSubmit(subscription, isEditing)}>
        {isEditing ? 'Update' : 'Save'}
      </Button>
    </VStack>
  );
  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box p={5}>
      <Button colorScheme="blue" onClick={onAddOpen} mb={4}>
        Add Subscription
      </Button>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Amount</Th>
            <Th>Start Date</Th>
            <Th>Period</Th>
            <Th>Next Billing</Th>
            <Th>Reminder</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {subscriptions.map((sub) => (
            <Tr key={sub.ID}>
              <Td>{sub.Name}</Td>
              <Td>{sub.Amount}</Td>
              <Td>{format(parseISO(sub.StartDate), 'yyyy-MM-dd')}</Td>
              <Td>{sub.Period}</Td>
              <Td>{format(parseISO(sub.NextBilling), 'yyyy-MM-dd')}</Td>
              <Td>{format(parseISO(sub.Reminder), 'yyyy-MM-dd')}</Td>
              <Td>
                <HStack spacing={2}>
                  <Button colorScheme="yellow" size="sm" onClick={() => handleEdit(sub)}>
                    Edit
                  </Button>
                  <Button colorScheme="red" size="sm" onClick={() => handleDelete(sub)}>
                    Delete
                  </Button>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SubscriptionForm 
              subscription={newSubscription} 
              setSubscription={setNewSubscription} 
              onSubmit={handleSubmit}
              isEditing={false}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SubscriptionForm 
              subscription={editingSubscription} 
              setSubscription={setEditingSubscription} 
              onSubmit={handleSubmit}
              isEditing={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Subscription
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the subscription "{deletingSubscription?.Name}"? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Subscriptions;