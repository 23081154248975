import React, { useState, useEffect } from 'react';
import { subMonths, getMonth, getYear } from 'date-fns';
import { getVPAGroupTransactions } from '../utils/dataProcessing';
import {
  VStack, HStack, Text, Button, Spinner, Select, Table, Thead, Tbody, Tr, Th, Td,
  Box, Heading, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure
} from "@chakra-ui/react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const VpaSpendingTab = () => {
  const [vpaData, setVpaData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const [timeRange, setTimeRange] = useState('1 month');
  const [selectedMonth, setSelectedMonth] = useState(getMonth(new Date()));
  const [selectedYear, setSelectedYear] = useState(getYear(new Date()));
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const calculateStartDate = (range) => {
    const today = new Date();
    if (range === '1 month') return new Date(selectedYear, selectedMonth, 1);
    if (range === '3 months') return subMonths(today, 3);
    if (range === '6 months') return subMonths(today, 6);
    return subMonths(today, 12);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const startDate = calculateStartDate(timeRange);
      const endDate = timeRange === '1 month' 
        ? new Date(selectedYear, selectedMonth + 1, 0) 
        : new Date();
      const data = await getVPAGroupTransactions(startDate, endDate);
      
      // Sort data by amount and get top 5
      const sortedData = data.sort((a, b) => b.amount - a.amount);
      const top5 = sortedData.slice(0, 5);
      const others = sortedData.slice(5);
      const otherSum = others.reduce((sum, item) => sum + item.amount, 0);
      
      if (otherSum > 0) {
        top5.push({ vpa: 'Others', amount: otherSum });
      }
      
      setVpaData(top5);
      setOthersData(others);
      setIsLoading(false);
    };
    fetchData();
  }, [timeRange, selectedMonth, selectedYear]);

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  const total = vpaData.reduce((sum, item) => sum + item.amount, 0);

  const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, vpa }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text 
        x={x} 
        y={y} 
        fill="#333333" 
        textAnchor={x > cx ? 'start' : 'end'} 
        fontSize="14px"
      >
        {`${vpa.length > 10 ? vpa.substr(0, 10) + '...' : vpa}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box bg="white" p={2} border="1px solid #ccc" borderRadius="md">
          <Text><strong>VPA:</strong> {data.vpa}</Text>
          <Text><strong>Amount:</strong> ₹{data.amount.toFixed(2)}</Text>
          <Text><strong>Percentage:</strong> {((data.amount / total) * 100).toFixed(2)}%</Text>
        </Box>
      );
    }
    return null;
  };
  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
      <VStack spacing={6} align="stretch">
        <Heading size="lg">Spending Analysis</Heading>

        <HStack spacing={4}>
          <Select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="1 month">1 Month</option>
            <option value="3 months">3 Months</option>
            <option value="6 months">6 Months</option>
            <option value="1 year">1 Year</option>
          </Select>

          {timeRange === '1 month' && (
            <>
              <Select value={selectedMonth} onChange={(e) => setSelectedMonth(Number(e.target.value))}>
                {MONTHS.map((month, index) => (
                  <option key={month} value={index}>{month}</option>
                ))}
              </Select>
              <Select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
                {[...Array(5)].map((_, i) => {
                  const year = getYear(new Date()) - i;
                  return <option key={year} value={year}>{year}</option>;
                })}
              </Select>
            </>
          )}
        </HStack>

        <HStack spacing={8} align="start">
        <Box width="50%">
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={vpaData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={150}
                fill="#8884d8"
                dataKey="amount"
                label={<CustomLabel />}
              >
                {vpaData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>

        </Box>
        <Box width="50%">
          <Table variant="simple" size="sm">
      
            <Thead>
              <Tr>
                <Th>VPA</Th>
                <Th isNumeric>Amount (₹)</Th>
                <Th isNumeric>Percentage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {vpaData.map((item, index) => (
                <Tr key={index}>
                  <Td  className='w-screen'>
                    {item.vpa === 'Others' ? (
                      <Button size="sm" onClick={onOpen}>Others</Button>
                    ) : (
                      item.vpa
                    )}
                  </Td>
                  <Td isNumeric>{item.amount.toFixed(2)}</Td>
                  <Td isNumeric>{((item.amount / total) * 100).toFixed(2)}%</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Text fontWeight="bold" className='m-5'>
          Total Spending: ₹{total.toFixed(2)}
        </Text>
        </Box>
        </HStack>
   

      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxW="50%" // 60% of screen width
        maxH="80%" // 50% of screen height
        position="relative"
        overflow="hidden" // Hide overflow
      >
        <ModalHeader
          position="sticky"
          top="0"
          backgroundColor="white"
          zIndex="docked"
          borderBottomWidth="1px"
        >
          Other VPAs
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY="auto"
          paddingBottom="10px" // Space for footer
        >
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>VPA</Th>
                <Th isNumeric>Amount (₹)</Th>
                <Th isNumeric>Percentage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {othersData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.vpa}</Td>
                  <Td isNumeric>{item.amount.toFixed(2)}</Td>
                  <Td isNumeric>{((item.amount / total) * 100).toFixed(2)}%</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          backgroundColor="white"
          borderTopWidth="1px"
          padding="1rem"
        >
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </Box>
  );
};

export default VpaSpendingTab;