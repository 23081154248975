import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Flex, SimpleGrid } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import SignUpGoogle from '../Components/SignupGoogle';
import Navbar from '../Components/Navbar';

// Assuming you have these images imported
import image1 from '../assets/landingPage/image1.png';
import image2 from '../assets/landingPage/image2.png';
import image3 from '../assets/landingPage/image3.png';
import image4 from '../assets/landingPage/image4.png';
import image5 from '../assets/landingPage/image5.png';
import image6 from '../assets/landingPage/image6.png';
import image7 from '../assets/landingPage/image7.png';
import image8 from '../assets/landingPage/image8.png';
import hdfclogo from '../assets/landingPage/hdfclogo.png'

const LandingPage = () => {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 2 : prevIndex - 2));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2 >= images.length ? 0 : prevIndex + 2));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box bg="#303A43" minHeight="100vh" color="white">
      <Navbar />
      <Box pt="80px" px="20px"> {/* Increased top padding to prevent overlap */}
        <Flex direction="column" align="center" maxWidth="screen" mx="auto">
        <Text fontSize="4xl" fontWeight="bold" mb="6">
        <span className='text-white p-2' >Monitor all your</span>
        <span className='text-green-500 p-2'>UPI</span>
        <span className='text-white p-2'>Transactions at one place!</span>
          </Text>
         
          <SignUpGoogle />
          <Text fontSize="xl" mb="6" mt="4" className='text-center'>
        <span className='text-grey-200 ' >Login with your</span>
        <span className='text-red-500 px-2'>HDFC</span>
        <span className='text-grey-200'>Gmail Linked Account!</span>
          </Text>
          {/* Carousel Container */}
          <Box position="relative" width="100%" overflow="hidden" mb="10">
            <SimpleGrid columns={2} spacing={6}>
              {images.slice(currentIndex, currentIndex + 2).map((src, index) => (
                <Box key={index} position="relative" paddingTop="75%" width="100%"> {/* 4:3 aspect ratio */}
                  <Image 
                    src={src} 
                    alt={`Carousel image ${index + 1}`} 
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    // height="100%"
                    objectFit="cover" 
                    borderRadius="lg" 
                    shadow="xl" 
                  />
                </Box>
              ))}
            </SimpleGrid>

            {/* Navigation Arrows */}
            <Flex 
              position="absolute" 
              top="50%" 
              left="0" 
              right="0" 
              transform="translateY(-50%)" 
              justify="space-between" 
              px="4"
            >
              <Box 
                as="button" 
                onClick={handlePrev} 
                bg="rgba(0,0,0,0.5)" 
                color="white" 
                borderRadius="full" 
                p="3" 
                _hover={{ bg: "rgba(0,0,0,0.7)" }}
              >
                <ChevronLeftIcon size={24} />
              </Box>
              <Box 
                as="button" 
                onClick={handleNext} 
                bg="rgba(0,0,0,0.5)" 
                color="white" 
                borderRadius="full" 
                p="3" 
                _hover={{ bg: "rgba(0,0,0,0.7)" }}
              >
                <ChevronRightIcon size={24} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default LandingPage;