import { useState, useMemo } from "react";
import {
  Box,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Button,
  Alert,
  AlertIcon,
  TabPanel,
} from "@chakra-ui/react";

const TopSpendsTab = ({ topSpends }) => {
  const [filters, setFilters] = useState({
    type: "",
    sortBy: "date",
    sortOrder: "desc",
  });

  // Filter handler
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Sort and filter the top spends based on user input
  const filteredAndSortedSpends = useMemo(() => {
    let result = topSpends.filter((spend) => {
      return filters.type ? spend.type === filters.type : true;
    });

    result.sort((a, b) => {
      if (filters.sortBy === "date") {
        return new Date(b.date) - new Date(a.date);
      } else {
        return b.amount - a.amount;
      }
    });

    if (filters.sortOrder === "asc") {
      result.reverse();
    }

    return result;
  }, [topSpends, filters]);

  return (
    <TabPanel>
      {/* Filter Section */}
      <HStack spacing={4} mb={5} alignItems="center">
        <Select
          placeholder="Filter by Type"
          name="type"
          onChange={handleFilterChange}
          value={filters.type}
          w="200px"
        >
          <option value="">All</option>
          <option value="credit">Credit</option>
          <option value="debit">Debit</option>
        </Select>

        <Select
          placeholder="Sort by"
          name="sortBy"
          onChange={handleFilterChange}
          value={filters.sortBy}
          w="200px"
        >
          <option value="date">Date</option>
          <option value="amount">Amount</option>
        </Select>

        <Select
          placeholder="Sort order"
          name="sortOrder"
          onChange={handleFilterChange}
          value={filters.sortOrder}
          w="200px"
        >
          <option value="desc">Descending</option>
          <option value="asc">Ascending</option>
        </Select>

        <Button onClick={() => setFilters({ type: "", sortBy: "date", sortOrder: "desc" })}>
          Reset
        </Button>
      </HStack>

      {/* Display Filtered Results */}
      {filteredAndSortedSpends.length > 0 ? (
        <Box overflowX="auto" overflowY="scroll" maxH="450px">
          <Table variant="simple" colorScheme="teal" size="md">
            <Thead>
              <Tr>
                <Th>Amount</Th>
                <Th>VPA</Th>
                <Th>Date</Th>
                <Th>Type</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredAndSortedSpends.map((t, index) => (
                <Tr key={index}>
                  <Td>₹{t.amount?.toFixed(2) || "0.00"}</Td>
                  <Td>{t.vpa || "Unknown"}</Td>
                  <Td>{t.date || "Unknown Date"}</Td>
                  <Td>{t.type || "Unknown Type"}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Alert status="info">
          <AlertIcon />
          No top spends data available.
        </Alert>
      )}
    </TabPanel>
  );
};

export default TopSpendsTab;