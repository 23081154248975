import React, { useState } from 'react';
import { Spinner, Text, Button, useToast, HStack } from '@chakra-ui/react';
import { processAndStoreData, getTransactions } from '../utils/dataProcessing';
import api from '../utils/api';

const DataUpdateLoader = ({ onComplete }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchAndUpdateData = async () => {
    setIsLoading(true);
    setError(null);
    setProgress(0);

    try {
      const response = await api.get('/analytics/mobile/fetch-pending');
      const newData = response.data.data;

      // Get existing transactions
      const existingTransactions = await getTransactions();

      // Process and store new data
      const updatedData = {};
      for (const [date, transactions] of Object.entries(newData)) {
        // Remove existing transactions for the same date
        const existingIndex = existingTransactions.findIndex(t => t.date.startsWith(date));
        if (existingIndex !== -1) {
          existingTransactions.splice(existingIndex, 1);
        }
        updatedData[date] = transactions;
      }

      // Combine existing and new data
      const combinedData = {
        ...Object.fromEntries(existingTransactions.map(t => [t.date.split('T')[0], [t]])),
        ...updatedData
      };

      // Process and store the combined data
      await processAndStoreData({ data: combinedData });

      setProgress(100);
      toast({
        title: "Data Updated",
        description: "Your financial data has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position:"top"
      });

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error('Error during data update:', error);
      setError(`An error occurred while updating your data: ${error.message}`);
      toast({
        title: "Update Failed",
        description: `Failed to update data: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <Button colorScheme="red" onClick={fetchAndUpdateData}>Retry Update</Button>
    );
  }

  return (
    <HStack spacing={4}>
      {isLoading ? (
        <>
          <Spinner size="sm" />
          <Text fontSize="sm">Updating... {Math.round(progress)}%</Text>
        </>
      ) : (
        <Button colorScheme="teal" onClick={fetchAndUpdateData} size="sm">
          UPDATE DATA
        </Button>
      )}
    </HStack>
  );
};

export default DataUpdateLoader;