import React, { useState, useEffect, useCallback } from 'react';
import { Box, Heading, Spinner, VStack, HStack, Select, useToast, Spacer, Flex } from '@chakra-ui/react';
import { getTransactions, getMonthlySpending, getDailySpending } from '../utils/dataProcessing';
import HomeTransactionChart from '../Components/HomeTransactionChart';
import DataUpdateLoader from '../Components/DataUpdateLoader';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const currentMonth = new Date().getMonth();
const TransactionSection = ({ title, data, xKey, yKey, chartType, onTypeChange, onYearChange, onMonthChange, onChartTypeChange, showMonthSelect, averageCurrentMonth, averagePreviousMonth, isDaily, averageMonthlySpend, currentMonth }) => (
  <Box>
    <Heading size="md" mb={4}>{title}</Heading>
    <HStack spacing={4} mb={4}>
      <Select onChange={onTypeChange}>
        <option value="debit">Debit</option>
        <option value="credit">Credit</option>
      </Select>
      <Select onChange={onYearChange}>
        {[...Array(5)].map((_, i) => {
          const year = new Date().getFullYear() - i;
          return <option key={year} value={year}>{year}</option>;
        })}
      </Select>
      {showMonthSelect && (
        <Select onChange={onMonthChange} value={currentMonth}>
          {months.map((month, index) => (
            <option key={index} value={index}>{month}</option>
          ))}
        </Select>
      )}
      <Select onChange={onChartTypeChange} value={chartType}>
        <option value="bar">Bar Chart</option>
        <option value="line">Line Chart</option>
      </Select>
    </HStack>
    <HomeTransactionChart 
      data={data} 
      xKey={xKey} 
      yKey={yKey} 
      chartType={chartType}
      averageCurrentMonth={averageCurrentMonth}
      averagePreviousMonth={averagePreviousMonth}
      isDaily={isDaily}
      averageMonthlySpend={averageMonthlySpend}
    />
  </Box>
);
const Home = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [dailyFilters, setDailyFilters] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    type: 'debit'
  });
  const [monthlyFilters, setMonthlyFilters] = useState({
    year: new Date().getFullYear(),
    type: 'debit'
  });
  const [dailyChartType, setDailyChartType] = useState('bar');
  const [monthlyChartType, setMonthlyChartType] = useState('bar');
  
  const toast = useToast();

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedTransactions = await getTransactions();
      setTransactions(fetchedTransactions);
      console.log('Fetched transactions:', fetchedTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      toast({
        title: "Error fetching data",
        description: `${error.message}. Check console for more details.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    if (transactions.length > 0) {
      const dailySpending = getDailySpending(transactions, dailyFilters.year, dailyFilters.month + 1, dailyFilters.type);
      const formattedDailyData = Object.entries(dailySpending).map(([day, amount]) => {
        const dayTransactions = transactions.filter(t => {
          const transactionDate = new Date(t.date);
          return transactionDate.getDate() === parseInt(day) &&
                 transactionDate.getMonth() === dailyFilters.month &&
                 transactionDate.getFullYear() === dailyFilters.year &&
                 t.type === dailyFilters.type;
        });
        return {
          date: day,
          amount: amount,
          transactions: dayTransactions
        };
      });
      setDailyData(formattedDailyData);

      const monthlySpending = getMonthlySpending(transactions, monthlyFilters.year, monthlyFilters.type);
      const formattedMonthlyData = Object.entries(monthlySpending).map(([month, amount]) => ({
        date: months[parseInt(month)],
        amount: amount,
      }));
      setMonthlyData(formattedMonthlyData);
    }
  }, [transactions, dailyFilters, monthlyFilters]);

  const calculateAverage = (data) => {
    if (data.length === 0) return 0;
    const sum = data.reduce((acc, curr) => acc + curr.amount, 0);
    return sum / data.length;
  };

  const averageCurrentMonth = calculateAverage(dailyData);
  const averagePreviousMonth = calculateAverage(
    Object.entries(getDailySpending(
      transactions, 
      dailyFilters.month === 0 ? dailyFilters.year - 1 : dailyFilters.year, 
      dailyFilters.month === 0 ? 12 : dailyFilters.month, 
      dailyFilters.type
    )).map(([day, amount]) => ({ amount }))
  );
  const handleUpdateComplete = () => {
    // Refresh your transaction data
    fetchTransactions();
  };
  const averageMonthlySpend = calculateAverage(monthlyData);

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box p={5}>
      <VStack spacing={8} align="stretch">
      <Flex align="center">
          <Heading size="lg">Transaction Analysis</Heading>
          <Spacer />
          <DataUpdateLoader onComplete={handleUpdateComplete} />
        </Flex>
        <TransactionSection
          title="Daily Transactions (Click Bar for Detail View)"
          data={dailyData}
          xKey="date"
          yKey="amount"
          chartType={dailyChartType}
          onMonthChange={(e) => setDailyFilters({...dailyFilters, month: parseInt(e.target.value)})}
          onYearChange={(e) => setDailyFilters({...dailyFilters, year: parseInt(e.target.value)})}
          onTypeChange={(e) => setDailyFilters({...dailyFilters, type: e.target.value})}
          onChartTypeChange={(e) => setDailyChartType(e.target.value)}
          showMonthSelect={true}
          averageCurrentMonth={averageCurrentMonth}
          averagePreviousMonth={averagePreviousMonth}
          isDaily={true}
          currentMonth={dailyFilters.month} // Pass the current month from dailyFilters
        />

        <TransactionSection
          title="Monthly Transactions"
          data={monthlyData}
          xKey="date"
          yKey="amount"
          chartType={monthlyChartType}
          onYearChange={(e) => setMonthlyFilters({...monthlyFilters, year: parseInt(e.target.value)})}
          onTypeChange={(e) => setMonthlyFilters({...monthlyFilters, type: e.target.value})}
          onChartTypeChange={(e) => setMonthlyChartType(e.target.value)}
          showMonthSelect={false}
          isDaily={false}
          averageMonthlySpend={averageMonthlySpend}
        />
      </VStack>
    </Box>
  );
};

export default Home;