import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, Button, Stack, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box 
      as="nav" 
      position="absolute"
      top="0"
      left="0"
      right="0"
      zIndex="10"
      bg="rgba(0, 0, 0, 0.5)"  // Translucent black background
      backdropFilter="blur(5px)"  // Optional: adds a blur effect
      px={4} 
      py={3}
    >
      <Flex align="center" justify="space-between" wrap="wrap" maxW="1200px" mx="auto">
      <Flex align="center" mr={5}>
      <Link to="/">
      <Text fontSize="2xl" fontWeight="bold">
        <span className='text-white' >Money</span>
        <span className='text-green-500'>Lite</span>
      </Text>
      </Link>
    </Flex>

        <Box display={{ base: 'block', md: 'none' }}>
          <Button onClick={onToggle} bg="transparent" _hover={{ bg: 'whiteAlpha.200' }}>
            {isOpen ? <CloseIcon color="white" /> : <HamburgerIcon color="white" />}
          </Button>
        </Box>

        <Box
          display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          flexGrow={1}
        >
          <Stack
            spacing={8}
            align="center"
            justify={['center', 'space-between', 'flex-end', 'flex-end']}
            direction={['column', 'row', 'row', 'row']}
            pt={[4, 4, 0, 0]}
          >
            {/* <Link to="/">
              <Text display="block" className='text-lg' color="white" _hover={{ color: 'gray.300' }}>
                Landing
              </Text>
            </Link> */}
            {/* <Link to="/gallery">
              <Text display="block" className='text-lg' color="white" _hover={{ color: 'gray.300' }}>
                Gallery
              </Text>
            </Link>
            <Link to="/about">
              <Text display="block" className='text-lg' color="white" _hover={{ color: 'gray.300' }}>
                About
              </Text>
            </Link> */}
            <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSePYSYotK4TyP4Ip3Z8mL3NMptJeP3nXAewFb6btayGjmm9ug/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg text-white hover:text-gray-300"
            >
            ContactUs
          </a>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;