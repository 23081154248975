import React, { useState, useEffect } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

const ResponsiveWrapper = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  if (isMobile) {
    return (
      <Box className="flex items-center justify-center min-h-screen bg-gray-100">
        <VStack spacing={4} className="bg-red-100 border border-red-500 rounded-lg p-6 text-center">
          <Text fontSize="xl" fontWeight="bold">Mobile View Not Available</Text>
          <Text>Currently, this website is available in desktop view only. Please open it from a laptop or desktop computer.</Text>
          <Text>A mobile app version will be coming soon!</Text>
        </VStack>
      </Box>
    );
  }

  return children;
};

export default ResponsiveWrapper;